import clsxm from "immigram-ui-cmon/src/lib/clsxm";
import React, {
  FocusEvent,
  HTMLInputTypeAttribute,
  KeyboardEvent,
  ReactNode,
  useCallback,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";

import { Icon24Lock, Icon24Unlock } from "../Icons";
import { useAutoScrollToFieldWithError } from "../../hooks/useAutoScrollToFieldWithError";

export interface InputProps {
  name: string;
  type?: HTMLInputTypeAttribute;
  value?: string;
  label?: React.ReactNode;
  active?: boolean;
  description?: string;
  error?: string;
  freeze?: boolean;
  disabled?: boolean;
  className?: string;
  icon?: ReactNode;
  onChange?: (value: string) => void;
  after?: (focused: boolean) => ReactNode;
  onFocus?: () => void;
  onBlur?: (e: FocusEvent<HTMLElement>) => void;
  onClick?: () => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  onDisabledToggle?: (value: boolean) => void;
  children?: ReactNode;
  autoComplete?: boolean;
}

const Input = (props: InputProps) => {
  const { disabled, onDisabledToggle } = props;

  const [value, setValue] = useState(props.value ?? "");
  const [error, setError] = useState(props.error ?? "");
  const [focus, setFocus] = useState(props.active ?? false);
  const [labelMaxWidth, setLabelMaxWidth] = useState("0px");
  const id = props.name + useId();
  const inputElement = useRef<HTMLInputElement | null>(null);
  const containerElement = useRef<HTMLDivElement | null>(null);
  const inactive = disabled || props.freeze;

  useAutoScrollToFieldWithError(
    props.name,
    containerElement.current,
    !!props.error
  );

  useEffect(() => {
    setValue(props.value ?? "");
  }, [setValue, props.value]);

  useEffect(() => {
    setError(props.error ?? "");
  }, [props.error, setError]);

  useEffect(() => {
    if (props.active) {
      inactive && inputElement.current?.focus();
    }
    setFocus(props.active ?? false);
  }, [inputElement, props, inactive, setFocus]);

  useEffect(() => {
    if (inputElement && inputElement.current) {
      setLabelMaxWidth(getComputedStyle(inputElement.current).width);
    }
  }, [inputElement, props.after]);

  const handleChange = useCallback(
    (event: any) => {
      if (props.onChange) {
        props.onChange(event.target.value);
      }
      setValue(event.target.value);
    },
    [props, setValue]
  );

  const handleFocus = useCallback(() => {
    setFocus(true);
    props.onFocus && props.onFocus();
  }, [setFocus, props]);

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLElement>) => {
      setFocus(false);
      setError(props.error ?? "");
      props.onBlur && props.onBlur(event);
    },
    [setFocus, props]
  );

  const handleClick = useCallback(() => {
    props.onClick && props.onClick();
  }, [props]);

  const handleDisabledToggle = useCallback(() => {
    onDisabledToggle?.(!disabled);
  }, [onDisabledToggle, disabled]);

  // eslint-disable-next-line unused-imports/no-unused-vars
  const { onChange, ...restProps } = props;

  return props.type === "hidden" ? (
    <input
      className="hidden"
      {...restProps}
      autoComplete={props.autoComplete ? undefined : "off"}
    />
  ) : (
    <>
      <div
        onClick={handleClick}
        onFocus={() => handleFocus()}
        onBlur={handleBlur}
        ref={containerElement}
        className={clsxm(
          "min-w-64 duration-default group relative z-0 inline-flex h-14 w-full border-solid transition ease-in-out",
          props.className ?? "",
          error ? "border-2 border-blood-600" : "border border-gray-150",
          !focus && !error && !disabled ? "border-transparent" : "",
          !focus && !disabled
            ? "bg-gray-150 hover:bg-gray-150 group-hover:bg-gray-150"
            : "bg-gray-100",
          focus || disabled
            ? "bg-white-900"
            : "bg-gray-100 placeholder-shown:bg-gray-100",
          "rounded-2xl",
          disabled
            ? "cursor-default text-black-400"
            : "cursor-pointer text-black-900",
          !focus && !inactive ? "hover:cursor-pointer" : ""
        )}
      >
        <input
          id={id}
          disabled={inactive}
          onChange={handleChange}
          onKeyDown={props.onKeyDown}
          ref={inputElement}
          autoComplete={props.autoComplete ? undefined : "off"}
          className={clsxm(
            !focus && !disabled && "hover:cursor-pointer",
            "font-w-400 z-0 block grow overflow-hidden text-ellipsis whitespace-nowrap border-none bg-transparent px-4 text-base",
            "peer appearance-none pt-4 placeholder-shown:pt-5t focus:outline-none focus:ring-0"
          )}
          type={props.type}
          value={value}
          placeholder=" "
        />
        <label
          htmlFor={id}
          style={{ width: labelMaxWidth }}
          className={clsxm(
            "font-w-400 absolute left-[1rem] top-3.5 z-10 mr-8 origin-[0] -translate-y-2 text-black-500",
            "transform overflow-hidden text-ellipsis whitespace-nowrap text-sm text-black-500 duration-300",
            "peer-placeholder-shown:left-[1rem] peer-placeholder-shown:translate-y-0 peer-placeholder-shown:scale-100 peer-placeholder-shown:text-base",
            "peer-focus:left-[1rem] peer-focus:-translate-y-2 peer-focus:text-sm",
            !disabled && "hover:cursor-pointer"
          )}
        >
          {props.label}
        </label>
        {!!props.after && !disabled && (
          <div className="z-10 mt-4 inline-flex min-h-full pr-4">
            {props.after(focus)}
          </div>
        )}
        {(disabled || onDisabledToggle) && (
          <div className="z-10 mt-4 inline-flex min-h-full pr-4">
            {onDisabledToggle ? (
              <>
                {disabled ? (
                  <Icon24Lock
                    className="text-black-400"
                    onClick={handleDisabledToggle}
                  />
                ) : (
                  <Icon24Unlock
                    className="cursor-pointer text-black-400"
                    onClick={handleDisabledToggle}
                    active
                  />
                )}
              </>
            ) : (
              <Icon24Lock className="text-black-400" disabled={true} />
            )}
          </div>
        )}
        {props.icon && !disabled && (
          <div className="flex items-center pr-[18px]">{props.icon}</div>
        )}
      </div>
      {!error && !!props.description && (
        <div className="mt-1 ml-4 overflow-hidden text-ellipsis whitespace-nowrap text-sm text-black-600">
          {props.description}
        </div>
      )}
      {error && (
        <div className="whitespace-wrap mt-1 ml-4 text-ellipsis text-sm text-blood-600">
          {error}
        </div>
      )}
      {props.children ?? <></>}
    </>
  );
};

export default Input;

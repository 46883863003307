import { object, string, ValidationError as YupValidationError } from "yup";

export function isEmailValid(email: string) {
  const validationSchema = object().shape({
    email: string().email().required(),
  });

  try {
    validationSchema.validateSync({ email }, { abortEarly: false });
    // Validation passed successfully
    return true;
  } catch (error) {
    if (error instanceof YupValidationError) {
      return false;
    } else {
      // Another error occurred during validation
      throw error;
    }
  }
}

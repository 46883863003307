import { withSuperJSONPage as _withSuperJSONPage } from "babel-plugin-superjson-next/tools";
import { withSuperJSONProps as _withSuperJSONProps } from "babel-plugin-superjson-next/tools";
import Button, { ButtonType } from "immigram-ui-cmon/src/components/Button/Button";
import Heading, { HeadingTag } from "immigram-ui-cmon/src/components/Heading/Heading";
import { Icon24Check } from "immigram-ui-cmon/src/components/Icons";
import { Icon24Mail } from "immigram-ui-cmon/src/components/Icons/Icon24Mail";
import Input from "immigram-ui-cmon/src/components/Input/Input";
import Modal from "immigram-ui-cmon/src/components/Modal/Modal";
import Paragraph from "immigram-ui-cmon/src/components/Paragraph/Paragraph";
import { GetServerSideProps, GetServerSidePropsContext } from "next";
import { useRouter } from "next/router";
import { signIn } from "next-auth/react";
import { getSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useEffect, useState } from "react";
import { isEmailValid } from "@/lib/email";
export const getServerSideProps: GetServerSideProps = _withSuperJSONProps(async (context: GetServerSidePropsContext) => {
  const {
    req
  } = context;
  const session = await getSession({
    req
  });
  if (session) {
    return {
      redirect: {
        destination: "/",
        permanent: false
      }
    };
  }
  const locales = await serverSideTranslations(context.locale ?? "en", ["application"]);
  return {
    props: {
      ...locales
    }
  };
}, []);
export const SignInPage = () => {
  const router = useRouter();
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [formIsDisabled, setFormIsDisabled] = useState(false);
  const [signInError, setSignInError] = useState(Boolean(router.query.error));
  const [showError, setShowError] = useState(false);
  const {
    t
  } = useTranslation();
  const [isValidationError, setIsValidationError] = useState(false);
  useEffect(() => {
    setSignInError(Boolean(router.query.error));
  }, [router.query.error]);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const handleSubmit = async () => {
    if (email) {
      setIsLoading(true);
      setSignInError(false);
      if (isValidationError) {
        setShowError(true);
        setIsLoading(false);
        return;
      }
      signIn("email", {
        redirect: false,
        email
      }).then(() => {
        setIsModalOpened(true);
        setIsLoading(false);
        setFormIsDisabled(true);
      }).catch(() => {
        setIsLoading(false);
        setSignInError(true);
      });
    }
  };
  const handleChange = (email: string) => {
    setEmail(email);
    const isValid = isEmailValid(email);
    setIsValidationError(!isValid);
    if (isValid) {
      setShowError(false);
    }
  };
  return <div className="bg-[url('/images/sign-in-page/Background.jpg')] bg-cover bg-center
    bg-no-repeat object-cover">
      <main className="flex h-screen items-center
    bg-black-600">
        <div className="relative m-auto rounded-3xl bg-white-900 p-8
    tablet:ml-[72px] tablet:w-[463px] medium:ml-[208px] medium:w-[496px] large:ml-[304px] large:w-[528px]">
          <img className="absolute top-[-55px] left-8 flex h-8 self-center" src="/images/logoSignIn/LogoImmigram.svg" alt="Immigram logo" />

          <div className="bg-white-900 sm:rounded-lg">
            <div className="space-y-8">
              <div className="md:col-span-1">
                <div className="mb-3 flex items-center">
                  <h3 className="font-granate text-4xl font-bold text-black-900">
                    {t("application:signin.signInPage.header.welcome")}
                  </h3>
                  <img className="ml-[5.81px] h-[29.83px] w-[29.83px]" src="/images/emoji/victory-hand.png" alt="hand victory sign" />
                </div>

                <p className="text-black-900">
                  {t("application:signin.signInPage.text.writeYourEmail")}
                </p>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="grid grid-cols-6 gap-8 ">
              <div className="col-span-6 sm:col-span-6">
                <Input className="border  border-blood-600" type="email" name="email" active={true} label="Email" value={email} error={showError && isValidationError ? (t("application:signin.signInPage.email.validation.error") as string) : undefined} disabled={formIsDisabled} icon={<Icon24Mail disabled={true} className="text-xl" />} onChange={handleChange} onKeyDown={event => {
                if (event.which == 13 || event.keyCode == 13) {
                  handleSubmit();
                }
              }} />
              </div>
            </div>
          </div>

          {signInError ? <div className="mt-4 text-sm text-red-600">
              {t("application:signin.signInPage.signInError")}
            </div> : null}

          <div className="mt-8 ml-auto">
            {formIsDisabled ? <p className="flex justify-center py-2.5 px-4 text-black-900">
                {t("application:signin.signInPage.text.checkYourEmailForTheAuthorisationLink")}
              </p> : <Button disabled={isValidationError && showError} onClick={handleSubmit} progress={isLoading} className="flex w-full" buttonClassName="justify-center">
                {t("application:signin.signInPage.button.sendMagicLink")}
              </Button>}
          </div>
        </div>
        <Modal isOpened={isModalOpened} onClose={() => setIsModalOpened(false)}>
          <div className="flex flex-col items-center p-8 text-center">
            <Icon24Check className="m-0 flex h-[96px] w-[96px] items-center justify-center rounded-3xl bg-green-50 p-0 text-[48px] font-semibold leading-[56px] text-green-800" />
            <Heading tag={HeadingTag.H4} className="mt-5 mb-3">
              {t("application:signin.signInPage.text.magicLinkHasBeenSent")}
            </Heading>
            <Paragraph muted={true} className="mb-6">
              {t("application:signin.signInPage.text.checkYourEmail")} {email}
            </Paragraph>
            <div className="flex justify-center gap-x-2">
              <Button type={ButtonType.Primary} onClick={() => setIsModalOpened(false)}>
                {t("application:common.got_it")}
              </Button>
            </div>
          </div>
        </Modal>
      </main>
    </div>;
};
export default _withSuperJSONPage(SignInPage);
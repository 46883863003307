import debounce from "lodash/debounce";
import memoize from "lodash/memoize";
import { useEffect, useRef } from "react";

const scrollToInputDebounced = memoize(
  debounce((pathPrefix) => {
    const sectionFields = GlobalFieldsStorage[pathPrefix];
    const field = sectionFields?.find((el) => el.isError);

    field?.ref.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, 100)
);

const GlobalFieldsStorage: Record<
  string,
  { fieldName: string; ref: HTMLDivElement; isError: boolean }[]
> = {};

export function useAutoScrollToFieldWithError(
  fieldPath: string,
  ref: HTMLDivElement | undefined | null,
  isError: boolean
) {
  useEffect(() => {
    const pathSections = fieldPath.split(".");
    const fieldName = pathSections.pop();
    const pathPrefix = pathSections.length ? pathSections.join(".") : "";

    if (!ref || !fieldName) return;

    GlobalFieldsStorage[pathPrefix] = [
      ...(GlobalFieldsStorage[pathPrefix] || []),
      { fieldName, ref, isError },
    ].sort((a, b) => {
      return a.ref.offsetTop - b.ref.offsetTop;
    });

    return () => {
      GlobalFieldsStorage[pathPrefix] = GlobalFieldsStorage[pathPrefix].filter(
        (item) => item.ref !== ref
      );
    };
  }, [fieldPath, ref, isError]);

  const prevIsError = useRef<boolean>();

  useEffect(() => {
    if (!prevIsError.current && isError) {
      const pathSections = fieldPath.split(".");
      pathSections.pop();
      const pathPrefix = pathSections.length ? pathSections.join(".") : "";

      scrollToInputDebounced(pathPrefix);
    }

    prevIsError.current = isError;
  }, [isError, fieldPath]);
}
